import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import ReCAPTCHA from "react-google-recaptcha";
import { AuthContext } from '../context/AuthContext';

// Ana Form Kabı
const FormContainer = styled.div`
  background-color: #ffffff;
  border-radius: 15px;
  padding: -30px;
  width: 100%;
  width: 270px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
`;

// Başlık
const FormHeading = styled.h2`
  text-align: center;
  color: #2c3e50;
  font-size: 1.5em;
  margin-bottom: 10px;
`;

// Input Alanlarını Kapsayan Grup
const InputGroup = styled.div`
  margin-bottom: 5px;
  width: 100%;
`;

// Etiketler
const Label = styled.label`
  display: block;
  margin-bottom: 0px;
  font-weight: bold;
  color: #2c3e50;
  font-size: 14px;
`;

// Input Stilleri
const Input = styled.input`
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1em;
  background-color: #f8f9fa;
  color: #333;
  transition: 0.3s;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

// Textarea Stili
const TextArea = styled.textarea`
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 1em;
  background-color: #f8f9fa;
  color: #333;
  resize: vertical;
  transition: 0.3s;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

// Buton
const SubmitButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: bold;
  width: 100%;
  margin-top: 15px;
  transition: 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

// reCAPTCHA Merkezi Hizalama
const RecaptchaContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0px;
`;

function AppointmentForm({ teacher, closeModal }) {
  const { authState } = useContext(AuthContext);
  const teacherEmail = authState.user?.email || '';

  const [formData, setFormData] = useState({
    client_name: '',
    client_phone: '',
    client_studentName: '',
    client_subject: teacher.subject,
    client_grade: '',
    client_city: '',
    client_schoolType: '',
    client_goals: '',
    client_additionalQuestions: '',
    teacher_email: teacherEmail,
  });

  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    console.log('Generated reCAPTCHA token:', token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      alert("Lütfen reCAPTCHA doğrulamasını tamamlayın.");
      return;
    }

    try {
      const response = await fetch('https://hocabul.net/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...formData, recaptchaToken, teacherEmail: teacher.email }),
      });

      if (response.ok) {
        alert('Randevu talebiniz başarıyla gönderildi!');
        closeModal();
      } else {
        alert('Bir hata oluştu. Lütfen tekrar deneyin.');
      }
    } catch (err) {
      console.error('Error submitting form:', err);
      alert('Sunucuya bağlanırken bir sorun oluştu.');
    }
  };

  return (
    <FormContainer>
      <FormHeading>Randevu Talebi</FormHeading>
      <form onSubmit={handleSubmit}>
        <InputGroup>
          <Label>İsim Soyisim</Label>
          <Input type="text" name="client_name" value={formData.client_name} onChange={handleChange} required />
        </InputGroup>
        
        <InputGroup>
          <Label>Telefon</Label>
          <Input type="text" name="client_phone" value={formData.client_phone} onChange={handleChange} required />
        </InputGroup>

        <InputGroup>
          <Label>Email</Label>
          <Input type="email" name="client_email" value={formData.client_email} onChange={handleChange} required />
        </InputGroup>

        <InputGroup>
          <Label>Kim Ders Alacak?</Label>
          <Input type="text" name="client_studentName" value={formData.client_studentName} onChange={handleChange} required />
        </InputGroup>

        <InputGroup>
          <Label>Hangi Ders?</Label>
          <Input type="text" name="client_subject" value={formData.client_subject} disabled />
        </InputGroup>

        <InputGroup>
          <Label>Öğrenci Kaça Gidiyor?</Label>
          <Input type="text" name="client_grade" value={formData.client_grade} onChange={handleChange} required />
        </InputGroup>

        <InputGroup>
          <Label>Yaşadığınız İl</Label>
          <Input type="text" name="client_city" value={formData.client_city} onChange={handleChange} required />
        </InputGroup>

        <InputGroup>
          <Label>Öğrenci Hangi Okul Türüne Gidiyor?</Label>
          <Input type="text" name="client_schoolType" value={formData.client_schoolType} onChange={handleChange} required />
        </InputGroup>

        <InputGroup>
          <Label>Beklentiniz veya Hedefiniz</Label>
          <TextArea name="client_goals" value={formData.client_goals} onChange={handleChange} rows="4" required />
        </InputGroup>

        <InputGroup>
          <Label>Sormak İstediğiniz Ekstra Bir Şey</Label>
          <TextArea name="client_additionalQuestions" value={formData.client_additionalQuestions} onChange={handleChange} rows="4" />
        </InputGroup>

        {/* reCAPTCHA */}
        <RecaptchaContainer>
          <ReCAPTCHA
            sitekey="6LcWkZEqAAAAAF3uQ3uCiRiW5ZnCKvHk4pp7ltbF"
            onChange={handleRecaptchaChange}
          />
        </RecaptchaContainer>

        <SubmitButton type="submit">Gönder</SubmitButton>
      </form>
    </FormContainer>
  );
}

export default AppointmentForm;
