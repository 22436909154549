import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import { AuthContext } from '../context/AuthContext';
import { jwtDecode } from 'jwt-decode'; // Token çözümlemesi için ekleyin



const Header = ({ setSearchQuery }) => {
  const { authState, logout } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userName, setUserName] = useState('Kullanıcı'); // Varsayılan isim
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  // Profil sayfasına geçiş
  const handleProfileClick = () => {
    navigate('/profile');
  };


 // ✅ State güncellendiğinde arama işlemini başlat
  useEffect(() => {
    if (inputValue.trim() !== '') {
      setSearchQuery(inputValue);
    }
  }, [inputValue, setSearchQuery]); // inputValue değiştiğinde çağrılacak



 const handleSearch = () => {
    setSearchQuery(inputValue); // Arama butonuna basıldığında sorguyu ayarla
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch(); // Enter tuşuna basıldığında da aramayı başlat
    }
  };

  // Mobil menü aç/kapat
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Sayfa yüklendiğinde token'dan kullanıcı adı kontrol et
  useEffect(() => {
    const token = localStorage.getItem('token'); // Token'ı localStorage'dan al
    if (token) {
      try {
        const decoded = jwtDecode(token); // Token'ı çöz
        setUserName(decoded.name || 'Kullanıcı'); // Token'dan adı al
      } catch (error) {
        console.error('Token çözümleme hatası:', error);
        logout(); // Token geçersizse çıkış yap
      }
    } else if (authState.user?.name) {
      // AuthState üzerinden kullanıcı adını al
      setUserName(authState.user.name);
    }
  }, [authState.user]); // AuthState değiştiğinde tetiklenir






   return (
    <header className="header">
      <div className="logo-container">
        <button className="logo-button" onClick={handleLogoClick}>
          <span className="desktop-logo-text">hocabul.net</span>
          <span className="mobile-logo-text">H</span>
        </button>
      </div>


  {/* Arama Kutusu ve Butonu */}
      <div className="search-container">
        <input
          type="text"
          placeholder="Eğitmen ara"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown} // Enter ile tetikleme
          className="search-input"
        />
       <button type="button" className="search-button" onClick={handleSearch}>
          Ara
        </button>
      </div>

      

      {/* Mobil Menü */}
      <div className="hamburger" onClick={toggleMenu} aria-label="Toggle Menu">
        ☰
      </div>

      {/* Auth Bölümü */}
      <nav className={`auth-container ${isMenuOpen ? 'open' : ''}`}>
        {authState.isTeacherAuthenticated ? ( // Eğer giriş yapılmışsa
          <div className="user-section">
            <span className="welcome-message">
              Hoşgeldiniz Hocam!
            </span>
            <button
              className="profile-button"
              onClick={handleProfileClick}
              aria-label="Go to Profile"
            >
              Profilim
            </button>
            <span className="divider">|</span>
            <span className="logout" onClick={logout}>
              Çıkış Yap
            </span>
          </div>
        ) : (
          // Eğer giriş yapılmamışsa
          <>
            <Link to="/login" className="auth-link">
              Öğretmen Giriş Yap
            </Link>
            <span className="divider">|</span>
            <Link to="/signup" className="auth-link">
              Öğretmen Hesap Aç
            </Link>
          </>
        )}
      </nav>
    </header>
  );
};

export default Header;
