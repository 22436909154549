import React, { useState, useEffect } from "react";
import "./AdWindow.css";

function AdWindow() {
  const [ad, setAd] = useState(null);
  const [isHidden, setIsHidden] = useState(false); // 🚀 Reklamın gizlenip gizlenmediğini kontrol et

  // ✅ **Sayfa yenilenince reklamı tekrar göster**
  useEffect(() => {
    fetchAd();
  }, []);

  const fetchAd = async () => {
    try {
      const response = await fetch("https://hocabul.net/api/ads/active");
      const data = await response.json();
      if (data) {
        setAd(data);
        setIsHidden(false); // 🚀 **Sayfa yenilenince reklamı tekrar göster**
      }
    } catch (error) {
      console.error("❌ Reklam yüklenirken hata oluştu:", error);
    }
  };

  // ✅ **Reklama tıklanınca tıklama sayısını artır**
  const handleClick = async () => {
    if (ad?.imageUrl) {
      try {
        await fetch(`https://hocabul.net/api/ads/${ad.id}/click`, {
          method: "POST",
        });
      } catch (error) {
        console.error("❌ Tıklama kaydedilirken hata oluştu:", error);
      }

      // 🌍 Kullanıcıyı hedef URL'ye yönlendir
      window.open(ad.targetUrl, "_blank");
    }
  };

  // ✅ **Reklamı kapatma işlemi**
  const handleCloseAd = async () => {
    if (ad?.id) {
      try {
        await fetch(`https://hocabul.net/api/ads/${ad.id}/close`, {
          method: "POST",
        });
      } catch (error) {
        console.error("❌ Reklam kapatma hatası:", error);
      }
    }
    setIsHidden(true); // 🚀 **Reklamı frontend'de gizle**
  };

  // **Eğer reklam kapatıldıysa veya yoksa ekrana hiçbir şey getirme**
  if (isHidden || !ad) {
    return null;
  }
  
  
  return (
    <div className="ad-container">
      {/* 🔥 Reklamı Kapat Butonu */}
      <button className="close-ad-button" onClick={handleCloseAd}>
        ✖ Reklamı Kapat
      </button>

      {/* 🔥 Reklam İçeriği */}
      <div className="ad-content" onClick={handleClick}>
        {ad?.imageUrl ? (
         <img
  src={ad?.imageUrl ? (ad.imageUrl.startsWith("https") ? ad.imageUrl : `https://hocabul.net${ad.imageUrl}`) : "/uploads/default-ad.png"}
  alt={ad?.name || "Reklam"}
  className="ad-image"
/>

        ) : (
          <div className="ad-placeholder">Bu alana reklam verebilirsiniz</div>
        )}
      </div>
    </div>
  );
}

export default AdWindow;
