import React, { useEffect, useState } from "react";
import "./AdManagement.css";

function AdManagement() {
  const [ads, setAds] = useState([]);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [newAd, setNewAd] = useState({
    name: "",
    startDate: "",
    endDate: "",
    imageFile: null, // 📌 Dosya yükleme için yeni alan
    targetUrl: "",
    active: false,
  });



//Reklamı aktif pasit yap 

const handleToggleActive = async (id, currentStatus) => {
  try {
    const response = await fetch(`https://hocabul.net/api/ads/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ active: !currentStatus }), // Mevcut durumu tersine çeviriyoruz
    });

    const result = await response.json();

    if (result.success) {
      setAds((prevAds) =>
        prevAds.map((ad) =>
          ad.id === id ? { ...ad, active: result.updatedAd.active } : ad
        )
      );
    }
  } catch (error) {
    console.error("❌ Aktif/Pasif durumu değiştirilemedi:", error);
  }
};





 // ✅ Reklamları API'den al
useEffect(() => {
  const fetchAds = async () => {
    try {
      const response = await fetch("https://hocabul.net/api/ads");
      const data = await response.json();
      console.log("📡 API'den Gelen Veriler:", data);
      setAds(data.ads);  // ✅ `data.ads` olarak güncellendi
    } catch (error) {
      console.error("❌ Reklam verisi alınırken hata oluştu:", error);
    }
  };

  fetchAds();
}, []);

  // ✅ Form girişlerini kontrol et
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAd((prev) => ({ ...prev, [name]: value }));
  };

  // ✅ Dosya yükleme işlemini yönet
  const handleFileChange = (e) => {
    setNewAd((prev) => ({ ...prev, imageFile: e.target.files[0] }));
  };

  // ✅ Yeni Reklam Ekleme
  const handleAddAd = async (e) => {
    e.preventDefault();

    if (!newAd.imageFile) {
      alert("Lütfen bir görsel seçin!");
      return;
    }

    // 📌 Dosya yükleme için FormData kullanıyoruz
    const formData = new FormData();
    formData.append("name", newAd.name);
    formData.append("startDate", newAd.startDate);
    formData.append("endDate", newAd.endDate);
    formData.append("targetUrl", newAd.targetUrl);
    formData.append("image", newAd.imageFile); // Görsel dosyası

    try {
      const response = await fetch("https://hocabul.net/api/ads", {
        method: "POST",
        body: formData, // FormData ile POST isteği
      });

      const result = await response.json();
      console.log("📡 API'ye Gönderilen Veri:", result);
      setAds([...ads, result.newAd]); // Yeni reklamı listeye ekle
      setIsAccordionOpen(false);
    } catch (error) {
      console.error("❌ Reklam eklenirken hata oluştu:", error);
    }
  };

  return (
  <div className="ad-management">
    <h2>Reklam Yönetimi</h2>

    {/* Akordiyon Butonu */}
    <button
      className={`accordion-button ${isAccordionOpen ? "open" : ""}`}
      onClick={() => setIsAccordionOpen(!isAccordionOpen)}
    >
      {isAccordionOpen ? "Reklam Ekle (Gizle)" : "+ Reklam Ekle"}
    </button>

    {/* Akordiyon İçeriği */}
    <div className={`accordion-content ${isAccordionOpen ? "show" : ""}`}>
      <h3>Yeni Reklam Ekle</h3>
      <form onSubmit={handleAddAd} encType="multipart/form-data">
        <label>Reklam Adı:</label>
        <input type="text" name="name" value={newAd.name} onChange={handleInputChange} required />

        <label>Başlangıç Tarihi:</label>
        <input type="date" name="startDate" value={newAd.startDate} onChange={handleInputChange} required />

        <label>Bitiş Tarihi:</label>
        <input type="date" name="endDate" value={newAd.endDate} onChange={handleInputChange} required />

        <label>Reklam Görseli:</label>
        <input type="file" name="image" accept="image/png" onChange={handleFileChange} required />

        <label>Hedef Link:</label>
        <input type="text" name="targetUrl" value={newAd.targetUrl} onChange={handleInputChange} required />

        <button type="submit" className="submit-button">Kaydet</button>
      </form>
    </div>

    {/* 📌 Eklenen Reklamları Listeleme Bölümü */}
    <div className="ads-list">
      <h3>Mevcut Reklamlar</h3>

      {/* Eğer reklam yoksa mesaj göster */}
      {ads.length === 0 ? (
        <p>Henüz eklenmiş bir reklam yok.</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Ad</th>
              <th>Başlangıç</th>
              <th>Bitiş</th>
              <th>Görsel</th>
              <th>Hedef URL</th>
              <th>Aktif</th>
            </tr>
          </thead>
         <tbody>
  {ads.map((ad) => (
    <tr key={ad.id}>
      <td>{ad.id}</td>
      <td>{ad.name}</td>
      <td>{ad.startDate}</td>
      <td>{ad.endDate}</td>
      <td>
        <img
          src={ad.imageUrl.startsWith("http") ? ad.imageUrl : `https://hocabul.net${ad.imageUrl}`}
          alt={ad.name}
          width="100"
        />
      </td>
      <td>
        <a href={ad.targetUrl} target="_blank" rel="noopener noreferrer">
          Link
        </a>
      </td>
      <td>
        <button
          className={ad.active ? "active-button" : "inactive-button"}
          onClick={() => handleToggleActive(ad.id, ad.active)}
        >
          {ad.active ? "✅ Aktif" : "❌ Pasif"}
        </button>
      </td>
    </tr>
  ))}
</tbody>

        </table>
      )}
    </div>
  </div>
);

}

export default AdManagement;
