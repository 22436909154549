import React, { useContext, useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import { AuthContext } from './context/AuthContext';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Team from './pages/Team';
import AppointmentForm from './components/AppointmentForm';
import Contact from './pages/Contact';
import AdminDashboard from './pages/AdminDashboard';
import AdminLogin from './pages/AdminLogin';
import ProfilePage from './pages/ProfilePage';
import TeacherApplication from './pages/TeacherApplication';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Prices from './pages/Prices';
import CV from './pages/CV';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import About from './pages/About';
import DeliveryAndRefund from './pages/DeliveryAndRefund';
import SalesContract from './pages/SalesContract';
import HowItWorks from './components/HowItWorks';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import PhotoTool from "./pages/PhotoTool";


function App() {
  const { authState } = useContext(AuthContext); // AuthState ile giriş durumu kontrolü
  const location = useLocation();
const [searchQuery, setSearchQuery] = useState('');



  // Admin rota koruması
  const ProtectedAdminRoute = ({ children }) => {
    if (!authState.isAdminAuthenticated) {
      // Admin giriş yapmadıysa, login sayfasına yönlendir
      return <Navigate to="/admin-login" state={{ from: location }} />;
    }
    return children;
  };

  return (
    <div className="App">
      <Header setSearchQuery={setSearchQuery} />
      <main className="content">
        <Routes>
          {/* Genel Rotalar */}
          <Route path="/" element={<Team searchQuery={searchQuery} />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/about" element={<About />} />
          <Route path="/sales-contract" element={<SalesContract />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/delivery-and-refund" element={<DeliveryAndRefund />} />
          <Route path="/prices" element={<Prices />} />

          {/* Admin Rotaları */}
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route
            path="/admin"
            element={
              <ProtectedAdminRoute>
                <AdminDashboard />
              </ProtectedAdminRoute>
            }
          />

          {/* Öğretmen ve Kullanıcı Rotaları */}
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/teacher-application" element={<TeacherApplication />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
		  <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/cv/:email" element={<CV />} />
          <Route path="/appointment/:id" element={<AppointmentForm />} />
		  <Route path="/photo-tool" element={<PhotoTool />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
