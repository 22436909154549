import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import BasicCVApplication from './BasicCVApplication';
import { AuthContext } from '../context/AuthContext'; // AuthContext'i import et
import './BasicCVApplication.css'; // CSS dosyasını dahil et

// Styled components to align with ProfileManagement
const CVContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background-color: #FFF;
  border-radius: 12px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #333;
`;

const SectionTitle = styled.h2`
  font-size: 1.5em;
  color: #333;
  text-align: center;
  margin-bottom: 15px;
`;

const InfoText = styled.p`
  font-size: 1em;
  color: #333;
  text-align: center;
  margin: 10px 0;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 15px;
`;

const CardButton = styled.button`
  flex: 1;
  background-color: ${({ isDelete }) => (isDelete ? '#ff4d4d' : '#4d9fff')};
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1em;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ isDelete }) => (isDelete ? '#e63939' : '#3a7ecc')};
  }
`;

const EmailInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 1em;
`;

function CV() {
  const { authState } = useContext(AuthContext); // AuthContext'ten kullanıcı bilgilerini al
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [newEmail, setNewEmail] = useState(authState.user?.email || ''); // E-posta alanını otomatik doldur
  const [membershipType, setMembershipType] = useState(null);

  // Submit handler
  const handleSubmit = async () => {
    if (!newEmail) {
      alert('E-posta adresi gerekli.');
      return;
    }

    try {
      const response = await fetch('https://hocabul.net/api/submit-basic-cv', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: newEmail, /* Diğer form verileri */ }),
      });

      if (response.ok) {
        alert('CV başarıyla gönderildi!');
      } else {
        const error = await response.json();
        alert(`Hata: ${error.message}`);
      }
    } catch (error) {
      console.error('CV gönderimi sırasında hata:', error);
      alert('CV gönderimi sırasında bir hata oluştu.');
    }
  };

  // Delete handler
  const handleDelete = async () => {
    if (!newEmail) {
      alert('E-posta adresi bulunamadı.');
      return;
    }

    const confirmDelete = window.confirm('CV\'nizi silmek istediğinizden emin misiniz?');
    if (!confirmDelete) return;

    try {
      const response = await fetch('https://hocabul.net/api/delete-basic-cv', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: newEmail }),
      });

      if (response.ok) {
        alert('CV başarıyla silindi!');
      } else {
        const error = await response.json();
        alert(`Hata: ${error.message}`);
      }
    } catch (error) {
      console.error('CV silinirken hata:', error);
      alert('CV silinirken bir hata oluştu.');
    }
  };
  
  
  
// Üyelik durumunu kontrol et
  useEffect(() => {
    const fetchMembershipStatus = async () => {
      try {
        const response = await fetch(
          `https://hocabul.net/api/membership-status/${encodeURIComponent(authState.user?.email)}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch membership status');
        }
        const data = await response.json();
        setMembershipType(data.membership_type);
      } catch (error) {
        console.error('Error fetching membership status:', error);
      }
    };

    if (authState.user?.email) {
      fetchMembershipStatus();
    }
  }, [authState.user?.email]);

  return (
    <CVContainer>
      <SectionTitle>Temel CV</SectionTitle>
      <InfoText>
        {membershipType === 'monthly-type' || membershipType === 'yearly-type'
          ? 'Aboneliğiniz aktif. CV bilgilerinizi düzenleyebilirsiniz.'
          : 'Aylık veya yıllık abonelik ile CV bilgilerinizi düzenleyebilirsiniz.'}
      </InfoText>

      {membershipType === 'monthly-type' || membershipType === 'yearly-type' ? (
        <>
          <CardButton onClick={() => setIsFormVisible(!isFormVisible)}>
            {isFormVisible ? 'Formu Gizle' : 'Başvuru Yap'}
          </CardButton>
          {isFormVisible && (
            <div>
              <BasicCVApplication />
           

            </div>
          )}
        </>
      ) : (
        <CardButton onClick={() => alert('Paketler bölümünden Aylık veya Yıllık Abonelik satın almanız gerekmektedir.')}>Abone Ol</CardButton>
      )}
    </CVContainer>
  );
}

export default CV;

