// TeacherCard Component
import React, { useEffect, useState } from 'react';
import {FaUniversity, FaBook,FaGlobe, FaEye, FaMapMarkerAlt, FaClock,FaAward,FaCrown , FaMoneyBillAlt,FaPaperPlane, FaClipboardList, FaChalkboardTeacher, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { MdStars } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import './TeacherCard.css';
import AppointmentForm from '../components/AppointmentForm';

function TeacherCard({ teacher }) {
   const navigate = useNavigate();
  const [membershipType, setMembershipType] = useState(null);
  const [isFreeLesson, setIsFreeLesson] = useState(false);
  const [totalRequests, setTotalRequests] = useState(0);
  const [showAppointmentForm, setShowAppointmentForm] = useState(false);
  const [membershipStartDate, setMembershipStartDate] = useState(null);
  const [isFounder, setIsFounder] = useState(false);
  const [isTop10, setIsTop10] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  
 
  
  
  
  
  
  
  

  useEffect(() => {
  const fetchMembershipData = async () => {
    try {
      const response = await fetch("https://hocabul.net/api/members");
      if (!response.ok) {
        throw new Error("Failed to fetch membership data");
      }

      const members = await response.json();
      const member = members.find(
        (m) => m.email.toLowerCase() === teacher.email.toLowerCase()
      );

      if (member) {
        setMembershipType(member.membership_type);
        setMembershipStartDate(member.membership_start_date);

        // Founder Teacher Rozet Kontrolü
        const founderCutoffDate = new Date("2025-02-17");
        const teacherStartDate = new Date(member.membership_start_date);

        if (
          member.membership_type === "yearly-type" &&
          teacherStartDate < founderCutoffDate
        ) {
          setIsFounder(true);
        }
      } else {
        console.warn(`No membership found for email: ${teacher.email}`);
      }

      setIsFreeLesson(teacher.promotion?.toLowerCase() === "evet");
    } catch (error) {
      console.error("Error fetching membership data:", error);
    }
  };
 fetchClickCount();
  fetchMembershipData();
  fetchTotalRequests(); // Talep sayısını almak için fonksiyonu çağırıyoruz
}, [teacher.email, teacher.promotion]);
  
  
   const fetchTotalRequests = async () => {
      try {
        const response = await fetch(`https://hocabul.net/api/request-count/${encodeURIComponent(teacher.email)}`);
        if (!response.ok) {
          throw new Error('Failed to fetch total requests');
        }
        const data = await response.json();
        setTotalRequests(data.count || 0); // Update state with API response or default to 0
      } catch (error) {
        console.error('Error fetching total requests:', error);
      }
    };
  
  const fetchTop10Teachers = async () => {
  try {
    const response = await fetch("https://hocabul.net/api/top-10-teachers");
    if (!response.ok) {
      throw new Error("Failed to fetch top 10 teachers");
    }
    const topTeachers = await response.json(); // API'den dönen e-posta listesi

    // Öğretmenin e-postası Top 10 listesinde mi kontrol et
    const isTeacherInTop10 = topTeachers.includes(teacher.email.toLowerCase());

    setIsTop10(isTeacherInTop10); // State güncelle
  } catch (error) {
    console.error("Error fetching top 10 teachers:", error);
  }
};
  

fetchTop10Teachers();
fetchTotalRequests();

// 📌 Formatı düzelten fonksiyon
const formatText = (format) => {
  if (!format) return "";
  switch (format.toLowerCase()) {
    case "yüzyüze":
      return "Yüzyüze";
    case "online":
      return "Online";
    case "yüzyüze veya online":
      return "Yüzyüze veya Online";
    default:
      return format;
  }
};



// 📌 İsimleri ve soyisimleri doğru şekilde formatlayan fonksiyon
const formatName = (name) => {
  if (!name) return "";
  return name
    .toLowerCase() // Tüm harfleri küçük yap
    .split(" ") // Kelimeleri ayır
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // İlk harfi büyük yap
    .join(" "); // Kelimeleri tekrar birleştir
};


const extractNumber = (value) => {
  const match = value.match(/\d+/); // Sayıyı bul
  return match ? parseInt(match[0], 10) : 0; // Sayıyı döndür, yoksa 0
};


const formatLessonDuration = (duration) => {
  const numericValue = extractNumber(duration); // Sayısal değeri al
  return numericValue > 3 ? `${numericValue} Dakika` : `${numericValue} Saat`;
};

const formatDepartmentName = (department) => {
  return department
    .toLowerCase() // Önce tüm harfleri küçük yap
    .split(" ") // Kelimeleri boşluklardan ayır
    .map(word => (word === "ve" ? word : word.charAt(0).toUpperCase() + word.slice(1))) // "ve" küçük kalacak
    .join(" "); // Tekrar birleştir
};






 

  // Tıklama Sayısını Çekme
  const fetchClickCount = async () => {
    try {
      const response = await fetch(
        `https://hocabul.net/api/get-clicks/${encodeURIComponent(teacher.email)}`
      );
      if (!response.ok) throw new Error("Failed to fetch click count");
      const data = await response.json();
      setClickCount(data.clicks || 0);
    } catch (error) {
      console.error("Error fetching click count:", error);
    }
  };

  // Öğretmen Kartına Tıklanınca Tıklama Sayısını Arttır
  const handleCardClick = async () => {
  try {
    // Tıklama sayısını artır
    const response = await fetch(
      `https://hocabul.net/api/increment-click/${encodeURIComponent(teacher.email)}`,
      { method: "POST" }
    );
    if (!response.ok) throw new Error("Failed to increment click count");
    const data = await response.json();
    
    // Güncellenen tıklama sayısını state'e kaydet
    setClickCount(data.clicks);

    // CV sayfasına yönlendir
    navigate(`/cv/${teacher.email}`);
  } catch (error) {
    console.error("Error incrementing click count:", error);
  }
};





  const toggleModal = () => {
    setShowAppointmentForm((prevState) => !prevState);
  };

  return (
    <div className="teacher-card">
	<div className="clickable-area" onClick={handleCardClick}>
      {/* Üst Kısım (Fiverr Stili) */}
      <div className="teacher-header">
        {/* Profil Fotoğrafı */}
        <img
          src={teacher.photoPath ? `/uploads/${teacher.photoPath}` : "/images/default-teacher.png"}
          alt={`${teacher.name} ${teacher.lastname}`}
          className="profile-photo"
        />

       <div className="user-info">
  {/* Kullanıcı İsmi */}
  <div className="user-name">
   {formatName(teacher.name)} {formatName(teacher.lastname)}
  </div>
  
   <div className="user-subject">
     {teacher.subject} 
   </div>

  {/* Rozetler */}
  <div className="badges-container">
    {isFounder && (
      <span className="founder-badge">
        <MdStars className="badge-icon" /> Akıncı
      </span>
    )}
    
    {isTop10 && (
      <span className="top10-badge">
        <FaCrown className="badge-icon" /> Top 10
      </span>
    )}
  </div>

           

           {/* Talep Sayısı ve Tıklama Sayısı */}
            <div className="badge-container">
              <span className="request-count">
                <FaClipboardList className="request-count-icon" /> {totalRequests} Talep Aldım
              </span>
              <span className="click-count">
                <FaEye className="click-icon" /> {clickCount}
              </span>
            </div>
          </div>
        </div>





      {/* Bilgiler */}
      <div className="info-box">
        <FaUniversity className="info-icon bg-blue" />
        <div className="info-details">
          <span className="info-title">Üniversite</span>
          <span className="info-value">{teacher.university}</span>
        </div>
      </div>

      <div className="info-box">
        <FaBook className="info-icon bg-blue" />
        <div className="info-details">
          <span className="info-title">Bölüm</span>
          <span className="info-value">{formatDepartmentName(teacher.department)}</span>
        </div>
      </div>
	  
	  <div className="info-box">
        <FaGlobe className="info-icon bg-blue" />
        <div className="info-details">
          <span className="info-title">Format</span>
         <span className="info-value">{formatText(teacher.format)}</span>
        </div>
      </div>
	  

      {/* Çift Sütunlu Bilgiler */}
      <div className="two-columns">
        <div className="info-box">
          <FaMapMarkerAlt className="info-icon bg-blue" />
          <div className="info-details">
            <span className="info-title">Yer</span>
            <span className="info-value">{teacher.yer}</span>
          </div>
        </div>

        <div className="info-box">
          <FaClock className="info-icon bg-blue" />
          <div className="info-details">
            <span className="info-title">Ders Süresi</span>
           <span className="info-value">{formatLessonDuration(teacher.time)}</span>
          </div>
        </div>
      </div>

      <div className="two-columns">
        <div className="info-box">
          <FaChalkboardTeacher className="info-icon bg-blue" />
          <div className="info-details">
            <span className="info-title">Tecrübe</span>
            <span className="info-value">{teacher.experience} Yıl</span>
          </div>
        </div>

        <div className="info-box">
          <FaMoneyBillAlt className="info-icon bg-blue" />
          <div className="info-details">
            <span className="info-title">Ücret</span>
            <span className="info-value">{teacher.fee} ₺</span>
          </div>
        </div>
      </div>

      {/* Ücretsiz Ders */}
      <div className="info-box">
        {isFreeLesson ? <FaCheckCircle className="info-icon bg-green" /> : <FaTimesCircle className="info-icon bg-red" />}
        <div className="info-details">
          <span className="info-title">İlk Ders Ücretsiz</span>
          <span className={`info-value ${isFreeLesson ? "success-text" : "error-text"}`}>
            {isFreeLesson ? "Evet" : "Hayır"}
          </span>
        </div>
      </div>
</div>
      {/* Butonlar */}
<div className="button-container">
  {!showAppointmentForm && ( // Eğer form açıksa buton görünmeyecek
    <button className="apply-button" onClick={toggleModal}>
      <FaPaperPlane className="button-icon" />
      İletişime Geç
    </button>
  )}

  {/* Modal (Eğer Gösteriliyorsa) */}
  {showAppointmentForm && (
    <div className="appointment-modal">
      <div className="modal-content">
        <button className="close-modal" onClick={toggleModal}>X</button>
        <AppointmentForm teacher={teacher} closeModal={toggleModal} />
      </div>
    </div>
  )}
</div>

	
	
	
	
	
  </div>
  
);
}

export default TeacherCard;


