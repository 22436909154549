import React, { useState } from 'react';
import './TeacherApplication.css';

function TeacherApplication() {
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    email: '',
    university: '',
    department: '',
    experience: '',
    category: '',
    subject: '',
    time: '',
    format: '',
    fee: '',
    yer: '',
    promotion: 'Hayır',
    description: '',
  });

  const [photo, setPhoto] = useState(null);
  const [photoError, setPhotoError] = useState('');
  const [formError, setFormError] = useState('');
  const [submitted, setSubmitted] = useState(false);
  
  
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        setPhotoError('File size should be less than 2MB');
        setPhoto(null);
      } else if (file.type !== 'image/png') {
        setPhotoError('Only PNG images are allowed');
        setPhoto(null);
      } else {
        setPhotoError('');
        setPhoto(file);
      }
    }
  };

  const validateForm = () => {
    const requiredFields = [
      'name',
      'lastname',
      'email',
      'university',
      'department',
      'experience',
      'category',
      'subject',
      'time',
      'format',
      'fee',
      'yer',
      'description',
    ];

    for (const field of requiredFields) {
      if (!formData[field] || formData[field].trim() === '') {
        setFormError(`The field "${field}" is required.`);
        return false;
      }
    }

    if (!photo) {
      setFormError('A valid profile photo is required.');
      return false;
    }

    setFormError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key] || null); // Include null for empty fields
    }
    if (photo) {
      formDataToSend.append('photo', photo);
    }

    try {
      const response = await fetch('https://hocabul.net/api/submit-form', {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        const responseData = await response.json();
        setSubmitted(true);
        console.log(responseData.message);
      } else {
        const errorData = await response.json();
        setFormError(errorData.message || 'Failed to submit the form.');
      }
    } catch (error) {
      setFormError('An error occurred while submitting the form.');
      console.error('Error during form submission:', error);
    }
  };

  const universities = [
    'Alaylı',
	'Kazakistan Yabancı Diller ve Mesleki Kariyer Üniversitesi',
	'Abdullah Gül Üniversitesi',
'Acıbadem Mehmet Ali Aydınlar Üniversitesi',
'Adana Alparslan Türkeş Bilim ve Teknoloji Üniversitesi',
'Adıyaman Üniversitesi',
'Afyon Kocatepe Üniversitesi',
'Afyonkarahisar Sağlık Bilimleri Üniversitesi',
'Ağrı İbrahim Çeçen Üniversitesi',
'Akdeniz Üniversitesi',
'Aksaray Üniversitesi',
'Alanya Alaaddin Keykubat Üniversitesi',
'Alanya Üniversitesi',
'Altınbaş Üniversitesi',
'Amasya Üniversitesi',
'Anadolu Üniversitesi',
'Anka Teknoloji Üniversitesi',
'Ankara Bilim Üniversitesi',
'Ankara Hacı Bayram Veli Üniversitesi',
'Ankara Medipol Üniversitesi',
'Ankara Müzik ve Güzel Sanatlar Üniversitesi',
'Ankara Sosyal Bilimler Üniversitesi',
'Ankara Üniversitesi',
'Ankara Yıldırım Beyazıt Üniversitesi',
'Antalya Belek Üniversitesi',
'Antalya Bilim Üniversitesi',
'Ardahan Üniversitesi',
'Artvin Çoruh Üniversitesi',
'Ataşehir Adıgüzel Meslek Yüksekokulu',
'Atatürk Üniversitesi',
'Atılım Üniversitesi',
'Avrasya Üniversitesi',
'Aydın Adnan Menderes Üniversitesi',
'Bahçeşehir Üniversitesi',
'Balıkesir Üniversitesi',
'Bandırma Onyedi Eylül Üniversitesi',
'Bartın Üniversitesi',
'Başkent Üniversitesi',
'Batman Üniversitesi',
'Bayburt Üniversitesi',
'Beykoz Üniversitesi',
'Bezm-i Âlem Vakıf Üniversitesi',
'Bilecik Şeyh Edebali Üniversitesi',
'Bingöl Üniversitesi',
'Biruni Üniversitesi',
'Bitlis Eren Üniversitesi',
'Boğaziçi Üniversitesi',
'Bolu Abant İzzet Baysal Üniversitesi',
'Burdur Mehmet Akif Ersoy Üniversitesi',
'Bursa Teknik Üniversitesi',
'Bursa Uludağ Üniversitesi',
'Çağ Üniversitesi',
'Çanakkale Onsekiz Mart Üniversitesi',
'Çankaya Üniversitesi',
'Çankırı Karatekin Üniversitesi',
'Çukurova Üniversitesi',
'Demiroğlu Bilim Üniversitesi',
'Dicle Üniversitesi',
'Doğuş Üniversitesi',
'Dokuz Eylül Üniversitesi',
'Düzce Üniversitesi',
'Ege Üniversitesi',
'Erciyes Üniversitesi',
'Erzincan Binali Yıldırım Üniversitesi',
'Erzurum Teknik Üniversitesi',
'Eskişehir Osmangazi Üniversitesi',
'Eskişehir Teknik Üniversitesi',
'Fatih Sultan Mehmet Vakıf Üniversitesi',
'Fenerbahçe Üniversitesi',
'Fırat Üniversitesi',
'Galatasaray Üniversitesi',
'Gazi Üniversitesi',
'Gaziantep İslam Bilim ve Teknoloji Üniversitesi',
'Gaziantep Üniversitesi',
'Gebze Teknik Üniversitesi',
'Giresun Üniversitesi',
'Gümüşhane Üniversitesi',
'Hacettepe Üniversitesi',
'Hakkari Üniversitesi',
'Haliç Üniversitesi',
'Harran Üniversitesi',
'Hasan Kalyoncu Üniversitesi',
'Hatay Mustafa Kemal Üniversitesi',
'Hitit Üniversitesi',
'Iğdır Üniversitesi',
'Isparta Uygulamalı Bilimler Üniversitesi',
'Işık Üniversitesi',
'İbn Haldun Üniversitesi',
'İhsan Doğramacı Bilkent Üniversitesi',
'İnönü Üniversitesi',
'İskenderun Teknik Üniversitesi',
'İstanbul Arel Üniversitesi',
'İstanbul Atlas Üniversitesi',
'İstanbul Aydın Üniversitesi',
'İstanbul Beykent Üniversitesi',
'İstanbul Bilgi Üniversitesi',
'İstanbul Esenyurt Üniversitesi',
'İstanbul Galata Üniversitesi',
'İstanbul Gedik Üniversitesi',
'İstanbul Gelişim Üniversitesi',
'İstanbul Kent Üniversitesi',
'İstanbul Kültür Üniversitesi',
'İstanbul Medeniyet Üniversitesi',
'İstanbul Medipol Üniversitesi',
'İstanbul Nişantaşı Üniversitesi',
'İstanbul Okan Üniversitesi',
'İstanbul Rumeli Üniversitesi',
'İstanbul Sabahattin Zaim Üniversitesi',
'İstanbul Sağlık ve Teknoloji Üniversitesi',
'İstanbul Teknik Üniversitesi',
'İstanbul Ticaret Üniversitesi',
'İstanbul Topkapı Üniversitesi',
'İstanbul Üniversitesi',
'İstanbul Üniversitesi-Cerrahpaşa',
'İstanbul Yeni Yüzyıl Üniversitesi',
'İstanbul 29 Mayıs Üniversitesi',
'İstinye Üniversitesi',
'İzmir Bakırçay Üniversitesi',
'İzmir Demokrasi Üniversitesi',
'İzmir Ekonomi Üniversitesi',
'İzmir Katip Çelebi Üniversitesi',
'İzmir Tınaztepe Üniversitesi',
'İzmir Yüksek Teknoloji Enstitüsü',
'Kadir Has Üniversitesi',
'Kafkas Üniversitesi',
'Kahramanmaraş İstiklal Üniversitesi',
'Kahramanmaraş Sütçü İmam Üniversitesi',
'Kapadokya Üniversitesi',
'Karabük Üniversitesi',
'Karadeniz Teknik Üniversitesi',
'Karamanoğlu Mehmetbey Üniversitesi',
'Kastamonu Üniversitesi',
'Kayseri Üniversitesi',
'Kırıkkale Üniversitesi',
'Kırklareli Üniversitesi',
'Kırşehir Ahi Evran Üniversitesi',
'Kilis 7 Aralık Üniversitesi',
'Kocaeli Sağlık ve Teknoloji Üniversitesi',
'Kocaeli Üniversitesi',
'Koç Üniversitesi',
'Konya Gıda ve Tarım Üniversitesi',
'Konya Teknik Üniversitesi',
'KTO Karatay Üniversitesi',
'Kütahya Dumlupınar Üniversitesi',
'Kütahya Sağlık Bilimleri Üniversitesi',
'Lokman Hekim Üniversitesi',
'Malatya Turgut Özal Üniversitesi',
'Maltepe Üniversitesi',
'Manisa Celâl Bayar Üniversitesi',
'Mardin Artuklu Üniversitesi',
'Marmara Üniversitesi',
'MEF Üniversitesi',
'Mersin Üniversitesi',
'Mimar Sinan Güzel Sanatlar Üniversitesi',
'Mudanya Üniversitesi',
'Muğla Sıtkı Koçman Üniversitesi',
'Munzur Üniversitesi',
'Muş Alparslan Üniversitesi',
'Necmettin Erbakan Üniversitesi',
'Nevşehir Hacı Bektaş Veli Üniversitesi',
'Niğde Ömer Halisdemir Üniversitesi',
'Nuh Naci Yazgan Üniversitesi',
'Ondokuz Mayıs Üniversitesi',
'Ordu Üniversitesi',
'Orta Doğu Teknik Üniversitesi',
'Osmaniye Korkut Ata Üniversitesi',
'OSTİM Teknik Üniversitesi',
'Özyeğin Üniversitesi',
'Pamukkale Üniversitesi',
'Piri Reis Üniversitesi',
'Recep Tayyip Erdoğan Üniversitesi',
'Sabancı Üniversitesi',
'Sağlık Bilimleri Üniversitesi',
'Sakarya Uygulamalı Bilimler Üniversitesi',
'Sakarya Üniversitesi',
'Samsun Üniversitesi',
'Sanko Üniversitesi',
'Selçuk Üniversitesi',
'Siirt Üniversitesi',
'Sinop Üniversitesi',
'Sivas Bilim ve Teknoloji Üniversitesi',
'Sivas Cumhuriyet Üniversitesi',
'Süleyman Demirel Üniversitesi',
'Şırnak Üniversitesi',
'Tarsus Üniversitesi',
'TED Üniversitesi',
'Tekirdağ Namık Kemal Üniversitesi',
'TOBB Ekonomi ve Teknoloji Üniversitesi',
'Tokat Gaziosmanpaşa Üniversitesi',
'Toros Üniversitesi',
'Trabzon Üniversitesi',
'Trakya Üniversitesi',
'Türk Hava Kurumu Üniversitesi',
'Türk-Alman Üniversitesi',
'Türkiye Uluslararası İslam, Bilim ve Teknoloji Üniversitesi',
'Türkiye-Japon Bilim ve Teknoloji Üniversitesi',
'Ufuk Üniversitesi',
'Uşak Üniversitesi',
'Üsküdar Üniversitesi',
'Van Yüzüncü Yıl Üniversitesi',
'Yalova Üniversitesi',
'Yaşar Üniversitesi',
'Yeditepe Üniversitesi',
'Yıldız Teknik Üniversitesi',
'Yozgat Bozok Üniversitesi',
'Yüksek İhtisas Üniversitesi',
'Zonguldak Bülent Ecevit Üniversitesi',
'Doğu Akdeniz Üniversitesi',
'Girne Amerikan Üniversitesi',
'Uluslararası Kıbrıs Üniversitesi',
'Yakın Doğu Üniversitesi',
'Lefke Avrupa Üniversitesi',
'Kıbrıs İlim Üniversitesi',
'Kıbrıs Sağlık ve Toplum Bilimleri Üniversitesi',
'Kıbrıs Amerikan Üniversitesi',
'Kıbrıs Sosyal Bilimler Üniversitesi',
'Kıbrıs Batı Üniversitesi',
'Final Üniversitesi'
];


  const cities = [
    'Adana',
    'Adıyaman',
    'Afyonkarahisar',
    'Ağrı',
    'Aksaray',
    'Amasya',
    'Ankara',
    'Antalya',
    'Ardahan',
    'Artvin',
    'Aydın',
    'Balıkesir',
    'Bartın',
    'Batman',
    'Bayburt',
    'Bilecik',
    'Bingöl',
    'Bitlis',
    'Bolu',
    'Burdur',
    'Bursa',
    'Çanakkale',
    'Çankırı',
    'Çorum',
    'Denizli',
    'Diyarbakır',
    'Düzce',
    'Edirne',
    'Elazığ',
    'Erzincan',
    'Erzurum',
    'Eskişehir',
    'Gaziantep',
    'Giresun',
    'Gümüşhane',
    'Hakkâri',
    'Hatay',
    'Iğdır',
    'Isparta',
    'İstanbul Avrupa',
    'İstanbul Anadolu',
    'İzmir',
    'Kahramanmaraş',
    'Karabük',
    'Karaman',
    'Kars',
    'Kastamonu',
    'Kayseri',
    'Kırıkkale',
    'Kırklareli',
    'Kırşehir',
    'Kilis',
    'Kocaeli',
    'Konya',
    'Kütahya',
    'Malatya',
    'Manisa',
    'Mardin',
    'Mersin',
    'Muğla',
    'Muş',
    'Nevşehir',
    'Niğde',
    'Ordu',
    'Osmaniye',
    'Rize',
    'Sakarya',
    'Samsun',
    'Siirt',
    'Sinop',
    'Sivas',
    'Şanlıurfa',
    'Şırnak',
    'Tekirdağ',
    'Tokat',
    'Trabzon',
    'Tunceli',
    'Uşak',
    'Van',
    'Yalova',
    'Yozgat',
    'Zonguldak'
];


  return (
    <div className="teacher-application-form">
      <h2>Öğretmen Başvurusu</h2>
      {!submitted ? (
        <form onSubmit={handleSubmit}>
          {formError && <p className="error-message">{formError}</p>}

          <label htmlFor="name">Adınız:</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />

          <label htmlFor="lastname">Soyadınız:</label>
          <input type="text" id="lastname" name="lastname" value={formData.lastname} onChange={handleChange} required />

          <label htmlFor="email">E-posta Adresiniz:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />

          <label htmlFor="university">Mezun olduğunuz üniversite:</label>
          <select id="university" name="university" value={formData.university} onChange={handleChange} required>
            <option value="">Üniversite Seçin</option>
            {universities.map((university, index) => (
              <option key={index} value={university}>
                {university}
              </option>
            ))}
          </select>

          <label htmlFor="department">Mezun veya öğrenci olduğunuz bölüm:</label>
          <input type="text" id="department" name="department" value={formData.department} onChange={handleChange} required />

          <label htmlFor="experience">Özel ders tecrübe yılınız:</label>
          <input type="number" id="experience" name="experience" value={formData.experience} onChange={handleChange} min="0" required />

          <label htmlFor="category">Listelenmek istediğiniz ana kategori:</label>
          <select id="category" name="category" value={formData.category} onChange={handleChange} required>
            <option value="">Kategori Seçin</option>
            <option value="ilkokul">İlkokul</option>
            <option value="ortaokul">Ortaokul</option>
            <option value="lise">Lise</option>
            <option value="universite">Üniversite</option>
            <option value="yabanci-dil">Yabancı Dil</option>
            <option value="sinavlar">Sınavlar</option>
			 <option value="sinavlar">Sınavlar</option>
			<option value="kocluk">Koçluk</option>
			<option value="zeka-oyunu">Zeka Oyunu</option>
			<option value="sinavlar">Sınavlar</option>
			<option value="hizli-okuma">Hızlı Okuma</option>
			<option value="yazilim">Yazılım</option>
			<option value="spor">Spor</option>
			<option value="sanat">Sanat</option>
          </select>

          <label htmlFor="subject">Hangi dersi veriyorsunuz:</label>
          <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} required />

          <label htmlFor="time">Ders Süresi(Buraya sadece sayı giriniz. Ders süresi birimi saattir.):</label>
          <input type="text" id="time" name="time" value={formData.time} onChange={handleChange} required />

          <label htmlFor="format">Ders Formatı:</label>
          <select id="format" name="format" value={formData.format} onChange={handleChange} required>
            <option value="">Format Seçin</option>
            <option value="online">Online</option>
            <option value="yüzyüze">Yüzyüze</option>
            <option value="yüzyüze veya online">Yüzyüze veya Online</option>
          </select>

          <label htmlFor="fee">Ders Ücreti(Sadece sayı giriniz, birim otomatik eklenecektir):</label>
          <input type="text" id="fee" name="fee" value={formData.fee} onChange={handleChange} required />

          <label htmlFor="yer">Ders verdiğiniz şehir:</label>
          <select id="yer" name="yer" value={formData.yer} onChange={handleChange} required>
            <option value="">Şehir Seçin</option>
            {cities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>

          <label htmlFor="promotion">İlk dersiniz ücretsiz mi?</label>
          <select id="promotion" name="promotion" value={formData.promotion} onChange={handleChange} required>
            <option value="Hayır">Hayır</option>
            <option value="Evet">Evet</option>
          </select>

         <label htmlFor="photo">
  Profil Fotoğrafı (png, en fazla 2MB, 500x500 piksel): <br />
  <a href="https://hocabul.net/photo-tool" target="_blank" rel="noopener noreferrer">
    Fotoğrafınızı düzenlemek için aracımızı kullanabilirsiniz.
  </a> 
  Ücretsiz fotoğraf düzenleme hizmetimizden faydalanmak isterseniz fotoğrafınızı bize  
  <a href="https://wa.me/905433600559" target="_blank" rel="noopener noreferrer">
    bu numaradan
  </a> 
  gönderebilirsiniz. Birimimiz size en kısa zamanda dönüş yapacaktır.
</label>


          <input type="file" id="photo" name="photo" accept="image/png" onChange={handlePhotoChange} required />
          {photoError && <p className="error-message">{photoError}</p>}

          <label htmlFor="description">Kendinizi kısaca tanıtın(Yönetici onayı için):</label>
          <textarea id="description" name="description" value={formData.description} onChange={handleChange} rows="4" required></textarea>

          <button type="submit">Başvuruyu Gönder</button>
        </form>
      ) : (
        <div className="submission-message">
          <p>Sayın Hocam,</p>
          <p>Başvurunuz alındı. En kısa sürede sonuç tarafınıza e-mail ile bildirilecektir.</p>
        </div>
      )}
    </div>
  );
}

export default TeacherApplication;
