import React, { useState, useEffect } from 'react';
import './Team.css';
import TeacherCard from './TeacherCard';
import { useNavigate } from 'react-router-dom';
import AdWindow from './AdWindow'; // Reklam bileşenini içe aktarıyoruz

const categoryMap = {
  İLKOKUL: 'ilkokul',
  ORTAOKUL: 'ortaokul',
  LİSE: 'lise',
  ÜNİVERSİTE: 'universite',
  'YABANCI DİL': 'yabanci-dil',
  SINAVLAR: 'sinavlar',
  KOÇLUK: 'kocluk',
  'ZEKA OYUNU': 'zeka-oyunu',
  'HIZLI OKUMA': 'hizli-okuma',
  YAZILIM: 'yazilim',
  SPOR: 'spor',
  SANAT: 'sanat'
};

const categories = Object.keys(categoryMap);

function Team({ searchQuery }) {
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [teachers, setTeachers] = useState([]);
  const [filteredTeachers, setFilteredTeachers] = useState([]);
  const [allTeachers, setAllTeachers] = useState(null); // 🔹 Arama için tüm öğretmenler
  const [teacherCounts, setTeacherCounts] = useState({});
  const [location, setLocation] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [expertise, setExpertise] = useState('');
  const [format, setFormat] = useState('');
  const navigate = useNavigate();

  const handleCardClick = (teacherEmail) => {
    navigate(`/cv/${teacherEmail}`);
  };

  const resetFilters = () => {
    setLocation('');
    setMinPrice('');
    setMaxPrice('');
    setExpertise('');
    setFormat('');
    setFilteredTeachers(teachers);
  };

  // ✅ Başlangıçta ve kategori değiştiğinde, o kategorideki öğretmenleri getir
  useEffect(() => {
    const fetchTeachersByCategory = async () => {
      if (searchQuery) return; // 🔹 Arama yapılıyorsa kategoriye göre çekme
      try {
        const categoryKey = categoryMap[selectedCategory];
        const response = await fetch(`https://hocabul.net/api/category/${categoryKey}`);
        if (!response.ok) throw new Error(`Kategori verisi alınamadı: ${selectedCategory}`);

        const data = await response.json();
        setTeachers(data);
        setFilteredTeachers(data);
      } catch (error) {
        console.error('Kategoriye göre öğretmenleri çekerken hata:', error);
        setTeachers([]);
        setFilteredTeachers([]);
      }
    };

    fetchTeachersByCategory();
  }, [selectedCategory]);

  // ✅ Tüm kategorilerdeki öğretmen sayısını çekiyoruz
  useEffect(() => {
    const fetchCategoryCounts = async () => {
      try {
        const response = await fetch('https://hocabul.net/api/category-counts');
        if (!response.ok) throw new Error('Kategori sayımları alınamadı');

        const counts = await response.json();
        const formattedCounts = categories.reduce((acc, category) => {
          const key = categoryMap[category];
          acc[category] = counts[key] || 0;
          return acc;
        }, {});

        setTeacherCounts(formattedCounts);
      } catch (error) {
        console.error('Kategori sayımlarını çekerken hata:', error);
      }
    };

    fetchCategoryCounts();
  }, []); // 🔹 Sadece sayfa açıldığında çağrılır

  // ✅ İlk kez arama yapıldığında `all-teachers` API çağrılır
  useEffect(() => {
    const fetchAllTeachers = async () => {
      if (!searchQuery || allTeachers !== null) return; // 🔹 Daha önce çekildiyse tekrar çekme
      try {
        const response = await fetch('https://hocabul.net/api/all-teachers');
        if (!response.ok) throw new Error('Tüm öğretmenler alınamadı');

        const data = await response.json();
        setAllTeachers(data); // 🔹 Bellekte sakla, tekrar çekme
      } catch (error) {
        console.error('Tüm öğretmenleri çekerken hata:', error);
        setAllTeachers([]);
      }
    };

    fetchAllTeachers();
  }, [searchQuery]);



useEffect(() => {
  if (searchQuery.trim()) { // 🔹 Eğer sadece boşluk varsa arama yapma
    if (allTeachers) {
      const filtered = allTeachers.filter(teacher => {
        // 🔹 İsmi ve soyismi birleştir → "Merve Alagöz"
        const fullName = `${teacher.name} ${teacher.lastname}`.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        
        // 🔹 Kullanıcının yazdığı kelimeleri al → ["merve", "alagöz"]
        const searchWords = searchQuery.toLowerCase().trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").split(/\s+/);

        // 🔹 Kullanıcının yazdığı her kelimenin fullName içinde geçmesini kontrol eder
        return searchWords.every(word => fullName.includes(word));
      });

      setFilteredTeachers(filtered);
    }
  } else {
    setFilteredTeachers(teachers); // 🔹 Arama yoksa kategorideki öğretmenleri göster
  }
}, [searchQuery, teachers, allTeachers]);







  useEffect(() => {
    const filterTeachers = () => {
      let filtered = [...teachers];

      if (location) {
        filtered = filtered.filter((teacher) => teacher.yer === location);
      }

      if (expertise) {
        filtered = filtered.filter((teacher) =>
          teacher.subject.toLowerCase().includes(expertise.toLowerCase())
        );
      }

      if (minPrice) {
        filtered = filtered.filter(
          (teacher) => parseFloat(teacher.fee) >= parseFloat(minPrice)
        );
      }

      if (maxPrice) {
        filtered = filtered.filter(
          (teacher) => parseFloat(teacher.fee) <= parseFloat(maxPrice)
        );
      }

      if (format) {
        if (format === 'Hibrit') {
          filtered = filtered.filter(
            (teacher) =>
              teacher.format === 'yüzyüze' ||
              teacher.format === 'online' ||
              teacher.format === 'yüzyüze veya online'
          );
        } else {
          filtered = filtered.filter((teacher) => teacher.format === format);
        }
      }

      setFilteredTeachers(filtered);
    };

    filterTeachers();
  }, [location, minPrice, maxPrice, expertise, format, teachers]);

  const expertiseOptions = Array.from(new Set(teachers.map((teacher) => teacher.subject)));





  return (
    <div className="team-container">


      {/* Kategoriler */}
      <div className="filters-container">
        <div className="categories-grid">
          {categories.slice(0, 6).map((category) => (
            <button
              key={category}
              className={`category-button ${selectedCategory === category ? 'selected' : 'default'}`}
              onClick={() => setSelectedCategory(category)}
            >
              {category} ({teacherCounts[category] || 0})
            </button>
          ))}
        </div>
        <div className="categories-grid">
          {categories.slice(6).map((category) => (
            <button
              key={category}
              className={`category-button ${selectedCategory === category ? 'selected' : 'default'}`}
              onClick={() => setSelectedCategory(category)}
            >
              {category} ({teacherCounts[category] || 0})
            </button>
          ))}
        </div>

        {/* Filtreleme Alanı */}
        <div className="filters">
          <select className="filter-select" value={location} onChange={(e) => setLocation(e.target.value)}>
    <option value="">Tüm Şehirler</option>
    <option value="Adana">Adana</option>
    <option value="Adıyaman">Adıyaman</option>
    <option value="Afyonkarahisar">Afyonkarahisar</option>
    <option value="Ağrı">Ağrı</option>
    <option value="Aksaray">Aksaray</option>
    <option value="Amasya">Amasya</option>
    <option value="Ankara">Ankara</option>
    <option value="Antalya">Antalya</option>
    <option value="Ardahan">Ardahan</option>
    <option value="Artvin">Artvin</option>
    <option value="Aydın">Aydın</option>
    <option value="Balıkesir">Balıkesir</option>
    <option value="Bartın">Bartın</option>
    <option value="Batman">Batman</option>
    <option value="Bayburt">Bayburt</option>
    <option value="Bilecik">Bilecik</option>
    <option value="Bingöl">Bingöl</option>
    <option value="Bitlis">Bitlis</option>
    <option value="Bolu">Bolu</option>
    <option value="Burdur">Burdur</option>
    <option value="Bursa">Bursa</option>
    <option value="Çanakkale">Çanakkale</option>
    <option value="Çankırı">Çankırı</option>
    <option value="Çorum">Çorum</option>
    <option value="Denizli">Denizli</option>
    <option value="Diyarbakır">Diyarbakır</option>
    <option value="Düzce">Düzce</option>
    <option value="Edirne">Edirne</option>
    <option value="Elazığ">Elazığ</option>
    <option value="Erzincan">Erzincan</option>
    <option value="Erzurum">Erzurum</option>
    <option value="Eskişehir">Eskişehir</option>
    <option value="Gaziantep">Gaziantep</option>
    <option value="Giresun">Giresun</option>
    <option value="Gümüşhane">Gümüşhane</option>
    <option value="Hakkâri">Hakkâri</option>
    <option value="Hatay">Hatay</option>
    <option value="Iğdır">Iğdır</option>
    <option value="Isparta">Isparta</option>
    <option value="İstanbul Avrupa">İstanbul Avrupa</option>
    <option value="İstanbul Anadolu">İstanbul Anadolu</option>
    <option value="İzmir">İzmir</option>
    <option value="Kahramanmaraş">Kahramanmaraş</option>
    <option value="Karabük">Karabük</option>
    <option value="Karaman">Karaman</option>
    <option value="Kars">Kars</option>
    <option value="Kastamonu">Kastamonu</option>
    <option value="Kayseri">Kayseri</option>
    <option value="Kırıkkale">Kırıkkale</option>
    <option value="Kırklareli">Kırklareli</option>
    <option value="Kırşehir">Kırşehir</option>
    <option value="Kilis">Kilis</option>
    <option value="Kocaeli">Kocaeli</option>
    <option value="Konya">Konya</option>
    <option value="Kütahya">Kütahya</option>
    <option value="Malatya">Malatya</option>
    <option value="Manisa">Manisa</option>
    <option value="Mardin">Mardin</option>
    <option value="Mersin">Mersin</option>
    <option value="Muğla">Muğla</option>
    <option value="Muş">Muş</option>
    <option value="Nevşehir">Nevşehir</option>
    <option value="Niğde">Niğde</option>
    <option value="Ordu">Ordu</option>
    <option value="Osmaniye">Osmaniye</option>
    <option value="Rize">Rize</option>
    <option value="Sakarya">Sakarya</option>
    <option value="Samsun">Samsun</option>
    <option value="Siirt">Siirt</option>
    <option value="Sinop">Sinop</option>
    <option value="Sivas">Sivas</option>
    <option value="Şanlıurfa">Şanlıurfa</option>
    <option value="Şırnak">Şırnak</option>
    <option value="Tekirdağ">Tekirdağ</option>
    <option value="Tokat">Tokat</option>
    <option value="Trabzon">Trabzon</option>
    <option value="Tunceli">Tunceli</option>
    <option value="Uşak">Uşak</option>
    <option value="Van">Van</option>
    <option value="Yalova">Yalova</option>
    <option value="Yozgat">Yozgat</option>
    <option value="Zonguldak">Zonguldak</option>

          </select >
<input
  type="number"
  placeholder="Min"
  value={minPrice}
  onChange={(e) => setMinPrice(e.target.value)}
  className="filter-select" // Same class as the <select> element
/>
<input
  type="number"
  placeholder="Max"
  value={maxPrice}
  onChange={(e) => setMaxPrice(e.target.value)}
  className="filter-select" // Same class as the <select> element
/>
          {/* Yeni Format Filtreleme */}
          <select className="filter-select" value={format} onChange={(e) => setFormat(e.target.value)}>
            <option value="">Tüm Formatlar</option>
            <option value="yüzyüze">Yüz yüze</option>
            <option value="online">Online</option>
            <option value="Hibrit">Yüz yüze veya Online</option>
          </select>

          {/* Uzmanlık Filtreleme */}
          <select className="filter-select" value={expertise} onChange={(e) => setExpertise(e.target.value)}>
            <option value="">Tüm Uzmanlıklar</option>
            {expertiseOptions.map((exp, index) => (
              <option key={index} value={exp}>
                {exp}
              </option>
            ))}
          </select>
          <button className="reset-button" onClick={resetFilters}>SIFIRLA</button>
        </div>
      </div>


{/* Reklam Bileşeni */}
      <AdWindow />

      {/* Öğretmen Kartları */}
      <div className="teachers-container">
        <h2 className="teachers-title">{searchQuery ? `"${searchQuery}" için sonuçlar` : `${selectedCategory} Öğretmenleri`}</h2>
        <div className="teachers-grid">
          {filteredTeachers.length > 0 ? (
            filteredTeachers.map((teacher) => (
              <TeacherCard key={teacher.email} teacher={teacher} onCardClick={handleCardClick} />
            ))
          ) : (
            <p className="no-teachers">Bu kategoride öğretmen bulunamadı.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Team;
